import type { MetaFunction } from "@remix-run/node";

import { SignedIn, SignInButton, SignedOut, SignOutButton } from "@clerk/remix";

import Sidebar from "../components/Sidebar";
import DashboardCard from "../components/data/DashboardCard";
import KPICards from "../components/data/KPICards";
import { PageHeader } from "../components/layout/PageHeader";

export const meta: MetaFunction = () => {
  return [
    { title: "Vixel Dashboard" },
    { name: "description", content: "Welcome to Vixel Payments!" },
  ];
};

export default function Index() {
  return (
    <Sidebar>
      <PageHeader title="Dashboard" />

      <div className="mt-8 mb-8">
        <KPICards />
      </div>
      <div className="mt-8 mb-8">
        <DashboardCard />
      </div>

      <div style={{ lineHeight: "1.8" }}>
        <ul>
          <li>
            <a target="_blank" href="https://remix.run/docs" rel="noreferrer">
              Remix Docs
            </a>
          </li>
          <SignedOut>
            <li>
              <SignInButton>
                <button
                  type="button"
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >Sign In</button>
              </SignInButton>
            </li>
          </SignedOut>
          <SignedIn>
            <li>
              <SignOutButton>
                <button
                  type="button"
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >Sign Out</button>
              </SignOutButton>
            </li>
          </SignedIn>
        </ul>
      </div>
    </Sidebar>
  );
}
